import { Component } from "react";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../styles/Carousel.css"

//IMAGENES
import img1 from "../img/JPSACCAROUSEL1.jpg";
import img2 from "../img/JPSACCAROUSEL2.jpg";
import img3 from "../img/JPSACCAROUSEL3.jpg";
import img4 from "../img/JPSACCAROUSEL2.jpeg";
import img5 from "../img/JPSACCAROUSEL5.jpeg";

export default class AutoPlay extends Component {
    render() {
      const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        cssEase: "linear"
      };
      return (
        <div className = "carousel-principal">
          <Slider {...settings}>
            <div className = "container-img-carousel">
              <img className = "img-principal-carousel" src = {img3} alt="img" />
            </div>
            <div className = "container-img-carousel">
              <img className = "img-principal-carousel" src = {img2} alt="img" /> 
            </div>
            <div className = "container-img-carousel">
              <img className = "img-principal-carousel" src = {img1} alt="img" /> 
            </div>
            <div className = "container-img-carousel">
              <img className = "img-principal-carousel" src = {img4} alt="img" /> 
            </div>
            <div className = "container-img-carousel">
              <img className = "img-principal-carousel" src = {img5} alt="img" /> 
            </div>
          </Slider>
        </div>
      );
    }
  }