import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react";
import {faEnvelope, faPhone, faLocationDot} from "@fortawesome/free-solid-svg-icons"
import axios from "axios";
import "../styles/Footer.css"
import logoIVETEC from "../img/logoCITVJPSAC.webp";

function FooterComponent(){
    const [dataJson, setDataJson] = useState([]);

     const loadData = async() => {
        const result = await axios("data/data.json");
        if (result.data.length === 0 ){
            const data = require("../data/data.json");
            setDataJson(data);
        }else {
            setDataJson(result.data);
        }        
    }

    useEffect(() => {
        loadData();
    },[]);

    return(dataJson.length === 0 ?(
        <h2>Cargando..</h2>
    ) : (
        <footer>
            <div className = "container-footer">
                <img className = "logo-footer" src = {logoIVETEC} alt = "logo.webp" />
                <ul className = "list-icons">
                    <li className = "icon-item"><FontAwesomeIcon icon = {faEnvelope}/> Email <br/>
                        <span className = "span-item">{dataJson.contactos.correo}</span>
                    </li>
                    <li className = "icon-item"><FontAwesomeIcon icon = {faPhone}/> Teléfonos <br/>
                    <span className = "span-item">{dataJson.contactos.movil[0]} / {dataJson.contactos.movil[1]}</span> 
                    </li>
                    <li className = "icon-item"><FontAwesomeIcon icon = {faLocationDot}/> Dirección <br/>
                        <span className = "span-item">{dataJson.contactos.direccion}</span>
                    </li>
                </ul>
            </div>
            <div className = "mi-sello">
                <span>Hecho por CrFlores</span>
            </div>
        </footer>
        )       
    );
}

export default FooterComponent;